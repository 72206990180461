import React, { Fragment } from "react";

import { Link } from "react-router-dom";

import { useRecoilState, useRecoilValue } from "recoil";
import merchantInfoStore from "../../store/merchant-info.store";
import userInfoStore from "../../store/userInfo";
import sidebarStore from "../../store/sidebar.store";
import dropdownTrxListNotificationStore from "../../store/dropdown-notification.store";
import dropdownStore from "../../store/dropdown.store";
import themeModeState from "../../store/theme-app.store";

import NumberFormat from "react-number-format";

import DropdownTrxListNotification from "./DropdownTrxNotification";
import Dropdown from "./Dropdown";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import "./Navigation.css";

const Navigation: React.FC = (prop): JSX.Element => {

	const userInformationState = useRecoilValue(userInfoStore);

	const { saldo: ballance } = userInformationState;

	const sidebarState = useRecoilValue<boolean>(sidebarStore);

	const [isButtonSidebarState, setIsButtonSidebarState] = useRecoilState<boolean>(sidebarStore);

	const [isDropdownNotificationState, setIsDropdownNotificationState] = useRecoilState<boolean>(dropdownTrxListNotificationStore);

	const [isDropdownState, setIsDropdownState] = useRecoilState<boolean>(dropdownStore);

	function handleClickMinimalSidebar() {
		setIsButtonSidebarState(false);
	}

	function handleClickMaximalSidebar() {
		setIsButtonSidebarState(true);
	}

	function handleClickDropdownNotificationOpen(): void {
		setIsDropdownNotificationState(true);
		setIsDropdownState(false);
	};

	function handleClickdropdownNotificationHide(): void {
		setIsDropdownNotificationState(false);
	};

	function handleClickDropdownOpen(): void {
		setIsDropdownState(true);
		setIsDropdownNotificationState(false);
	};

	function handleClickdropdownHide(): void {
		setIsDropdownState(false);
	};

	const themeState = useRecoilValue<string | null>(themeModeState);

	const navThemeIconMode: string = themeState === "dark" ? "bg-dark-nav shadow-lg" : "bg-white shadow-lg";

	interface primaryThemeType {
		backgroundColor: string;
		color: string;
	}

	let primaryTheme: primaryThemeType = {
		backgroundColor: process.env.REACT_APP_PRIMARY_COLOR ? process.env.REACT_APP_PRIMARY_COLOR : "#FFFFFF",
		color: process.env.REACT_APP_PRIMARY_COLOR ? "#FFFFFF" : "#000000",
	};

	interface iconThemeType {
		color: string;
	}

	let iconTheme: iconThemeType = {
		color: "#FFFFFF",
	};

	if (themeState === 'dark') {
		primaryTheme['backgroundColor'] = "#1F283E";
		primaryTheme['color'] = "#FFFFFF";
		iconTheme['color'] = "#FFFFFF";
	}

	const iconConfig = {
		landscape: process.env.REACT_APP_LANDSCAPE_WEB_ICON,
		square: process.env.REACT_APP_SQUARE_WEB_ICON,
	}

	if (themeState === 'dark') {
		iconConfig['landscape'] = process.env.REACT_APP_LANDSCAPE_WEB_DARKMODE_ICON;
		iconConfig['square'] = process.env.REACT_APP_SQUARE_WEB_DARKMODE_ICON;
	}

	return (
		<nav className="hidden lg:block w-full h-16 fixed z-40 navbar-shadow" style={process.env.REACT_APP_PRIMARY_COLOR ? primaryTheme : { color: "#000000" }}>
			<div className="h-full flex justify-between items-center">
				<div className="h-full flex gap-10">
					{sidebarState ? (
						<Fragment>
							{/* LANDSCAPE ICON */}
							<Link to="/dashboard">
								<div className={`${navThemeIconMode} flex justify-center items-center logo-big-wrapper h-full px-5 cursor-pointer noselect`}>
									{iconConfig.landscape && <img src={iconConfig.landscape} className="block w-40 h-18" alt="icon" />}
								</div>
							</Link>
						</Fragment>
					) : (
						<Fragment>
							{/* SQUARE ICON */}
							<Link to="/dashboard">
								<div className={`${navThemeIconMode} flex items-center logo-small-wrapper h-full px-7 py-5 cursor-pointer noselect`} style={!iconConfig.square ? { width: "5.4rem" } : {}}>
									{iconConfig.square && <img src={iconConfig.square} alt="icon" width="30" />}
								</div>
							</Link>
						</Fragment>
					)}

					<div className="flex items-center lg:gap-8">
						{isButtonSidebarState ? (
							/* MINIMAL ICON MENU */
							<button onClick={handleClickMinimalSidebar} className="focus:outline-none">
								<FontAwesomeIcon icon={faBars} className="font-black text-2xl" style={iconTheme ? primaryTheme : { color: "#000000" }}></FontAwesomeIcon>
							</button>
						) : (
							/* MAXIMAL ICON MENU */
							<button onClick={handleClickMaximalSidebar} className="focus:outline-none">
								<FontAwesomeIcon icon={faBars} className="font-black text-2xl" style={iconTheme ? primaryTheme : { color: "#000000" }}></FontAwesomeIcon>
							</button>
						)}

						{/* USER BALLANCE */}
						<div className="flex items-center gap-2" title="Informasi Saldo">
							<NumberFormat
								value={ballance}
								className="font-medium text-base tracking-wider"
								displayType={"text"}
								thousandSeparator={"."}
								decimalSeparator={","}
								prefix={"Rp "}
							/>
						</div>
					</div>
				</div>

				<div className="flex items-center gap-6 pr-6 lg:pr-9">
					{/* TOPUP ICON */}
					<Link to="/dashboard/profile/user/topup">
						<button
							onClick={(): void => setIsDropdownState(false)}
							className="flex justify-center items-center px-3 py-1 bg-[#F3F4F6] text-gray-800 font-inter font-semibold rounded-full focus:outline-none"
							title="Tambah Saldo">
							<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.3" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
							Tambah Saldo
						</button>
					</Link>

					<div className="flex items-center gap-4">
						{/* ICON NOTIFICATION */}
						{isDropdownNotificationState ? (
							<button
								onClick={(): void => handleClickdropdownNotificationHide()}
								className=" focus:outline-none p-1 bg-[#F3F4F6] text-gray-800 rounded-full"
								title="Notifikasi Transaksi">
								<svg className="w-7 h-7" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M12 10.5199C11.59 10.5199 11.25 10.1799 11.25 9.76994V6.43994C11.25 6.02994 11.59 5.68994 12 5.68994C12.41 5.68994 12.75 6.02994 12.75 6.43994V9.76994C12.75 10.1899 12.41 10.5199 12 10.5199Z" fill="#292D32" />
									<path d="M12.0199 20.3502C9.43987 20.3502 6.86987 19.9402 4.41987 19.1202C3.50987 18.8202 2.81987 18.1702 2.51987 17.3502C2.21987 16.5302 2.31987 15.5902 2.80987 14.7702L4.07987 12.6502C4.35987 12.1802 4.60987 11.3002 4.60987 10.7502V8.65023C4.60987 4.56023 7.92987 1.24023 12.0199 1.24023C16.1099 1.24023 19.4299 4.56023 19.4299 8.65023V10.7502C19.4299 11.2902 19.6799 12.1802 19.9599 12.6502L21.2299 14.7702C21.6999 15.5502 21.7799 16.4802 21.4699 17.3302C21.1599 18.1802 20.4799 18.8302 19.6199 19.1202C17.1699 19.9502 14.5999 20.3502 12.0199 20.3502ZM12.0199 2.75023C8.75987 2.75023 6.10987 5.40023 6.10987 8.66023V10.7602C6.10987 11.5702 5.78987 12.7402 5.36987 13.4302L4.09987 15.5602C3.83987 15.9902 3.77987 16.4502 3.92987 16.8502C4.07987 17.2502 4.41987 17.5502 4.89987 17.7102C9.49987 19.2402 14.5599 19.2402 19.1599 17.7102C19.5899 17.5702 19.9199 17.2502 20.0699 16.8302C20.2299 16.4102 20.1799 15.9502 19.9499 15.5602L18.6799 13.4402C18.2599 12.7502 17.9399 11.5802 17.9399 10.7702V8.67023C17.9299 5.40023 15.2799 2.75023 12.0199 2.75023Z" fill="#292D32" />
									<path d="M11.9999 22.8998C10.9299 22.8998 9.87992 22.4598 9.11992 21.6998C8.35992 20.9398 7.91992 19.8898 7.91992 18.8198H9.41992C9.41992 19.4998 9.69992 20.1598 10.1799 20.6398C10.6599 21.1198 11.3199 21.3998 11.9999 21.3998C13.4199 21.3998 14.5799 20.2398 14.5799 18.8198H16.0799C16.0799 21.0698 14.2499 22.8998 11.9999 22.8998Z" fill="#292D32" />
								</svg>
							</button>
						) : (
							<button
								onClick={(): void => handleClickDropdownNotificationOpen()}
								className=" focus:outline-none p-1 bg-[#F3F4F6] text-gray-800 rounded-full"
								title="Notifikasi Transaksi">
								<svg className="w-7 h-7" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M12 10.5199C11.59 10.5199 11.25 10.1799 11.25 9.76994V6.43994C11.25 6.02994 11.59 5.68994 12 5.68994C12.41 5.68994 12.75 6.02994 12.75 6.43994V9.76994C12.75 10.1899 12.41 10.5199 12 10.5199Z" fill="#292D32" />
									<path d="M12.0199 20.3502C9.43987 20.3502 6.86987 19.9402 4.41987 19.1202C3.50987 18.8202 2.81987 18.1702 2.51987 17.3502C2.21987 16.5302 2.31987 15.5902 2.80987 14.7702L4.07987 12.6502C4.35987 12.1802 4.60987 11.3002 4.60987 10.7502V8.65023C4.60987 4.56023 7.92987 1.24023 12.0199 1.24023C16.1099 1.24023 19.4299 4.56023 19.4299 8.65023V10.7502C19.4299 11.2902 19.6799 12.1802 19.9599 12.6502L21.2299 14.7702C21.6999 15.5502 21.7799 16.4802 21.4699 17.3302C21.1599 18.1802 20.4799 18.8302 19.6199 19.1202C17.1699 19.9502 14.5999 20.3502 12.0199 20.3502ZM12.0199 2.75023C8.75987 2.75023 6.10987 5.40023 6.10987 8.66023V10.7602C6.10987 11.5702 5.78987 12.7402 5.36987 13.4302L4.09987 15.5602C3.83987 15.9902 3.77987 16.4502 3.92987 16.8502C4.07987 17.2502 4.41987 17.5502 4.89987 17.7102C9.49987 19.2402 14.5599 19.2402 19.1599 17.7102C19.5899 17.5702 19.9199 17.2502 20.0699 16.8302C20.2299 16.4102 20.1799 15.9502 19.9499 15.5602L18.6799 13.4402C18.2599 12.7502 17.9399 11.5802 17.9399 10.7702V8.67023C17.9299 5.40023 15.2799 2.75023 12.0199 2.75023Z" fill="#292D32" />
									<path d="M11.9999 22.8998C10.9299 22.8998 9.87992 22.4598 9.11992 21.6998C8.35992 20.9398 7.91992 19.8898 7.91992 18.8198H9.41992C9.41992 19.4998 9.69992 20.1598 10.1799 20.6398C10.6599 21.1198 11.3199 21.3998 11.9999 21.3998C13.4199 21.3998 14.5799 20.2398 14.5799 18.8198H16.0799C16.0799 21.0698 14.2499 22.8998 11.9999 22.8998Z" fill="#292D32" />
								</svg>
							</button>
						)}

						{isDropdownState ? (
							/* USER PROFILE PICTURE [FALSE] */
							<button
								onClick={(): void => handleClickdropdownHide()}
								className=" focus:outline-none mt-0.5 px-1 py-1 bg-[#F3F4F6] text-gray-800 rounded-full"
								title="Profil User">
								<svg className="w-7 h-7" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M12.1596 11.62C12.1296 11.62 12.1096 11.62 12.0796 11.62C12.0296 11.61 11.9596 11.61 11.8996 11.62C8.99957 11.53 6.80957 9.25 6.80957 6.44C6.80957 3.58 9.13957 1.25 11.9996 1.25C14.8596 1.25 17.1896 3.58 17.1896 6.44C17.1796 9.25 14.9796 11.53 12.1896 11.62C12.1796 11.62 12.1696 11.62 12.1596 11.62ZM11.9996 2.75C9.96957 2.75 8.30957 4.41 8.30957 6.44C8.30957 8.44 9.86957 10.05 11.8596 10.12C11.9096 10.11 12.0496 10.11 12.1796 10.12C14.1396 10.03 15.6796 8.42 15.6896 6.44C15.6896 4.41 14.0296 2.75 11.9996 2.75Z" fill="#292D32" />
									<path d="M12.1696 22.55C10.2096 22.55 8.23961 22.05 6.74961 21.05C5.35961 20.13 4.59961 18.87 4.59961 17.5C4.59961 16.13 5.35961 14.86 6.74961 13.93C9.74961 11.94 14.6096 11.94 17.5896 13.93C18.9696 14.85 19.7396 16.11 19.7396 17.48C19.7396 18.85 18.9796 20.12 17.5896 21.05C16.0896 22.05 14.1296 22.55 12.1696 22.55ZM7.57961 15.19C6.61961 15.83 6.09961 16.65 6.09961 17.51C6.09961 18.36 6.62961 19.18 7.57961 19.81C10.0696 21.48 14.2696 21.48 16.7596 19.81C17.7196 19.17 18.2396 18.35 18.2396 17.49C18.2396 16.64 17.7096 15.82 16.7596 15.19C14.2696 13.53 10.0696 13.53 7.57961 15.19Z" fill="#292D32" />
								</svg>

							</button>
						) : (
							/* USER PROFILE PICTURE [TRUE] */
							<button
								onClick={(): void => handleClickDropdownOpen()}
								className=" focus:outline-none mt-0.5 px-1 py-1 bg-[#F3F4F6] text-gray-800 rounded-full"
								title="Profil User">
								<svg className="w-7 h-7" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M12.1596 11.62C12.1296 11.62 12.1096 11.62 12.0796 11.62C12.0296 11.61 11.9596 11.61 11.8996 11.62C8.99957 11.53 6.80957 9.25 6.80957 6.44C6.80957 3.58 9.13957 1.25 11.9996 1.25C14.8596 1.25 17.1896 3.58 17.1896 6.44C17.1796 9.25 14.9796 11.53 12.1896 11.62C12.1796 11.62 12.1696 11.62 12.1596 11.62ZM11.9996 2.75C9.96957 2.75 8.30957 4.41 8.30957 6.44C8.30957 8.44 9.86957 10.05 11.8596 10.12C11.9096 10.11 12.0496 10.11 12.1796 10.12C14.1396 10.03 15.6796 8.42 15.6896 6.44C15.6896 4.41 14.0296 2.75 11.9996 2.75Z" fill="#292D32" />
									<path d="M12.1696 22.55C10.2096 22.55 8.23961 22.05 6.74961 21.05C5.35961 20.13 4.59961 18.87 4.59961 17.5C4.59961 16.13 5.35961 14.86 6.74961 13.93C9.74961 11.94 14.6096 11.94 17.5896 13.93C18.9696 14.85 19.7396 16.11 19.7396 17.48C19.7396 18.85 18.9796 20.12 17.5896 21.05C16.0896 22.05 14.1296 22.55 12.1696 22.55ZM7.57961 15.19C6.61961 15.83 6.09961 16.65 6.09961 17.51C6.09961 18.36 6.62961 19.18 7.57961 19.81C10.0696 21.48 14.2696 21.48 16.7596 19.81C17.7196 19.17 18.2396 18.35 18.2396 17.49C18.2396 16.64 17.7096 15.82 16.7596 15.19C14.2696 13.53 10.0696 13.53 7.57961 15.19Z" fill="#292D32" />
								</svg>
							</button>
						)}
					</div>
				</div>
			</div>

			{/* DROPDOWN COMPONENT */}
			{isDropdownState && <Dropdown animateClass="animate__animated animate__fadeIn" />}

			{/* DROPDOWN TRX NOTIFICATION COMPONENT LIST */}
			{isDropdownNotificationState && <DropdownTrxListNotification animateClass="animate__animated animate__fadeIn" />}
		</nav>
	);
};

const MobileNavigation: React.FC = (prop): JSX.Element => {
	const merchantInformationState = useRecoilValue(merchantInfoStore);

	const { kode_merchant: { nama: merchantName } } = merchantInformationState;

	const [isButtonSidebarState, setIsButtonSidebarState] = useRecoilState<boolean>(sidebarStore);

	function handleClickMinimalSidebar() {
		setIsButtonSidebarState(false);
	}

	function handleClickMaximalSidebar() {
		setIsButtonSidebarState(true);
	}

	return (
		<nav className="block lg:hidden w-full fixed bg-white z-30 navbar-shadow px-5">
			<div className="h-full flex justify-between items-center">
				{isButtonSidebarState ? (
					/* MINIMAL ICON MENU */
					<button onClick={handleClickMinimalSidebar} className="focus:outline-none">
						<svg className="w-6 h-6 sm:w-7 sm:h-7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" d="M4 6h16M4 12h8m-8 6h16"></path></svg>					</button>
				) : (
					/* MAXIMAL ICON MENU */
					<button onClick={handleClickMaximalSidebar} className="focus:outline-none">
						<svg className="w-6 h-6 sm:w-7 sm:h-7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" d="M4 6h16M4 12h8m-8 6h16"></path></svg>					</button>
				)}
				{/* MOBILE DEVICE MINIMAL ICON */}
				<Link to="/dashboard">
					<div className="h-16 sm:h-20 flex items-center ml-5 cursor-pointer noselect">
						<img src={process.env.REACT_APP_MOBILE_WEB_ICON} className="block w-[100px] sm:w-[130px]" alt={merchantName} />
					</div>
				</Link>
				<Link to="/dashboard/profile/trx/notification/show" className="focus:outline-none">
					<svg className="w-6 h-6 sm:w-7 sm:h-7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path></svg>
				</Link>
			</div>
		</nav>
	)
}

export { Navigation, MobileNavigation };
